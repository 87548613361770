// Allowed characters: Latin letters, Chinese characters, Korean characters, Japanese characters, numbers, and spaces
const allowedCharacters = /^[\p{Letter}\p{Mark}]+([\p{Letter}\p{Mark}'\-\. ]+)*$/u;
// Regex to identify if the input only contains spaces, single quotes, hyphens, or their combinations
const invalidCombination = /^['\-\.]+$/;

// Anti-shake, ensuring that the function is triggered only once within a period of time
function debounce(func, wait) {
  let timeout
  return function(...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}

function validateString(regex, str) {
  for (let i = 0; i < str.length; i++) {
    if (!regex.test(str[i])) {
      return false
    }
  }
  return true
}

export default {
  data: function() {
    return {
      allowGuestInformation: {},
      inputErrors: {
        first_name: { error: false, message: '' },
        last_name: { error: false, message: '' },
        email: { error: false, message: '' },
        phone: { error: false, message: '' },
        user_first_name: { error: false, message: '' },
        user_last_name: { error: false, message: '' },
      }
    }
  },
  methods: {
    toggleGuestInformation: function(id){
      this.allowGuestInformation[id] = !this.allowGuestInformation[id]
      this.$forceUpdate()
    },
    showGuestInformation: function(id){
      return this.allowGuestInformation[id]
    },
    validateField: function(value, fieldName, isSubmit = false) {
      // Here we verify email and phone. Since they are not within the scope of the PRD, we only do a simple null value check.
      if (fieldName === 'email' || fieldName === 'phone') {
        if (value.length === 0) {
          return { error: true, message: I18n.t('airhost.checkout.address.required_field') }
        } else {
          return { error: false, message: '' }
        }
      }

      // below verify first_name, last_name, user_first_name, user_last_name
      if (value.length === 0) {
        // room guest user allowed to leave the field empty
        if (fieldName === 'user_first_name' || fieldName === 'user_last_name') {
          return { error: false, message: '' }
        } else {
          return { error: true, message: I18n.t('airhost.checkout.address.required_field') }
        }
      }

      // add validation for only spaces, single hyphen, multiple hyphens, or invalid combinations when submit
      if (isSubmit && validateString(invalidCombination, value)) {
        return { error: true, message: I18n.t('airhost.checkout.address.invalid_name_input') }
      }

      if (!validateString(allowedCharacters, value)) {
        return { error: true, message: I18n.t('airhost.checkout.address.invalid_name_input') }
      } else {
        return { error: false, message: '' }
      }
    },
    validateInput: function(event, fieldName) {
      const inputField = event.target
      let value = inputField.value

      // Trim the value if the first character is a space
      if (value.startsWith(' ')) {
        value = value.trim()
        inputField.value = value
      }

      const validationResult = this.validateField(value, fieldName)
      this.inputErrors[fieldName].error = validationResult.error
      this.inputErrors[fieldName].message = validationResult.message
    },
    validateFieldOnSubmitForm: function(inputField, fieldName) {
      const value = inputField.value.trim()
      if (value === '') {
        // If only input spaces, the validation fails when you click submit and we need to clear these spaces.
        inputField.value = ''
      }

      const validationResult = this.validateField(value, fieldName, true)

      this.inputErrors[fieldName].error = validationResult.error
      this.inputErrors[fieldName].message = validationResult.message

      if (validationResult.error) {
        return false
      }

      return true
    },
    validateForm: function() {
      let isValid = true
      const fields = ['first_name', 'last_name', 'phone']
      fields.forEach(field => {
        const inputField = document.getElementById(`airhost_order_preferred_${field}`)
        if (inputField && !this.validateFieldOnSubmitForm(inputField, field)) {
          isValid = false
        }
      })
      const emailField = document.getElementById('airhost_order_email')
      if (emailField && !this.validateFieldOnSubmitForm(emailField, 'email')) {
        isValid = false
      }

      const guest_fields = ['user_first_name', 'user_last_name']
      guest_fields.forEach(field => {
        const inputField = document.getElementById(`airhost_order_line_items_attributes_0_preferred_${field}`)
        if (inputField && !this.validateFieldOnSubmitForm(inputField, field)) {
          isValid = false
        }
      })

      return isValid
    }
  },
  mounted: function() {
    let node = document.querySelector('[data-line-item-ids]')
    if (!!node) {
      node.dataset.lineItemIds.replace('[','').replace(']','').split(", ").forEach( (id) => {
        this.allowGuestInformation[id] = false
      })
    }

    if (this.$store.state.booking_engine_customer_info_reject_special_symbol_202410) {
      // listen to booker info input fields
      const fields = ['first_name', 'last_name', 'phone']
      fields.forEach(field => {
        const inputField = document.getElementById(`airhost_order_preferred_${field}`)
        if (inputField) {
          inputField.addEventListener('input', debounce((event) => this.validateInput(event, field), 300))
        }
      })
      const emailField = document.getElementById('airhost_order_email')
      if (emailField) {
        emailField.addEventListener('input', debounce((event) => this.validateInput(event, 'email'), 300))
      }

      // listen to room guest input fields
      const guest_fields = ['user_first_name', 'user_last_name']
      guest_fields.forEach(field => {
        const inputField = document.getElementById(`airhost_order_line_items_attributes_0_preferred_${field}`)
        if (inputField) {
          inputField.addEventListener('input', debounce((event) => this.validateInput(event, field), 300))
        }
      })

      // bind form submit event
      const form = document.querySelector('.edit_airhost_order')
      if (form) {
        form.addEventListener('ajax:beforeSend', function(event) {
          if (!this.validateForm()) {
            // popup error message
            window.notification(I18n.t('invalid_fields_before_checkout'), "danger", 3000)
            // prevent submit when form validate false
            event.preventDefault()
          }
        }.bind(this))
      }
    }
  }
}
